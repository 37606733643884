<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>
    <AppSmartList
      :filters="filters"
      :headers="headers"
      :actions="actions"
      :canCreate="true"
      :newRecordPage="newRecordPage"
      :editRecordPage="editRecordPage"
    ></AppSmartList>
  </div>
</template>

<script>
import AppSmartList from '@/components/base/AppSmartList.vue'
import { FORM_FIELD_TYPE, ROUTE_NAME, DATA_TABLE_LAYOUT } from '@/constants'
import { i18nHelper } from '@/utils'
import {
  NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS_LIST,
  NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE
} from '@/store/news-and-events.module'

export default {
  name: 'NewsAndEventsList',
  components: {
    AppSmartList
  },
  data: () => ({
    pageTitle: 'newsAndEvents',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'newsAndEvents',
        disabled: true
      }
    ],
    filters: [
      {
        model: [],
        label: 'dateRange',
        key: 'dateRange',
        type: FORM_FIELD_TYPE.DATE
      }
    ],
    headers: [
      {
        text: 'no',
        value: 'no'
      },
      {
        text: 'title',
        value: 'title'
      },
      {
        text: 'link',
        value: 'link'
      },
      {
        text: 'description',
        value: 'description',
        layout: DATA_TABLE_LAYOUT.APP_TABLE_DESCRIPTION,
        config: {
          isHtml: true,
          maxWidth: '150px'
        }
      },
      {
        text: 'updatedAt',
        value: 'updatedAt'
      },
      {
        text: 'createdAt',
        value: 'createdAt'
      },
      {
        text: 'actions',
        value: 'actions',
        sortable: false,
        layout: DATA_TABLE_LAYOUT.APP_TABLE_ACTIONS,
        actions: ['edit']
      }
    ],
    actions: {
      getList: {
        module: 'newsAndEvents',
        state: 'newsAndEventsList',
        action: NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS_LIST,
        title: i18nHelper.getMessage('text.getNewsAndEventsList')
      },
      initialGetListState:
        NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_LIST_STATE
    },
    newRecordPage: ROUTE_NAME.NEWS_AND_EVENTS_NEW,
    editRecordPage: ROUTE_NAME.NEWS_AND_EVENTS_DETAILS
  })
}
</script>

<style></style>
